// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const eventListeners = {};
export const notifyEventListeners = (type, ...args) => {
    eventListeners[type]?.forEach(listener => {
        listener.handleEvent(...args);
    });
};
export const notifyEventListenersAndAwaitHandlers = (type, ...args) => Promise.all(Array.from(eventListeners[type] ?? []).map(async (listener) => {
    try {
        await listener.handleEvent(...args);
    }
    catch (err) {
        throw err;
    }
}));
export const addEventListener = (type, handler) => {
    // If there is no listener set for the event type, just create it
    if (!eventListeners[type]) {
        eventListeners[type] = new Set();
    }
    const listener = {
        handleEvent: handler,
        remove: () => {
            eventListeners[type].delete(listener);
        },
    };
    eventListeners[type].add(listener);
    return {
        remove: () => listener.remove(),
    };
};
